import {dom, style, attr, ElemArg} from './dom'
import * as api from './api'

import Feature from 'ol/Feature'
import Map from 'ol/Map'
import OSM from 'ol/source/OSM'
import { WMTS } from 'ol/source'
import TileLayer from 'ol/layer/Tile'
import View from 'ol/View'
import VectorTileLayer from 'ol/layer/VectorTile'
import VectorTileSource from 'ol/source/VectorTile'
import MVT from 'ol/format/MVT'
import {Stroke, Style} from 'ol/style'
import {StyleFunction} from 'ol/style/Style'
import {DragBox, Select} from 'ol/interaction'
import {transform} from 'ol/proj'
import {platformModifierKeyOnly, platformModifierKey, always as conditionAlways} from 'ol/events/condition'
import MapBrowserEvent from 'ol/MapBrowserEvent'
import {Control, defaults as defaultControls} from 'ol/control'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import {get as getProjection} from 'ol/proj'
import {getTopLeft, getWidth} from 'ol/extent'

const client = new api.Client()

let map: Map
let select: Select | undefined
let door = '' // Uit URL hash, kan leeg zijn.

// Of we tracé aan het aanpassen, toevoegen of verwijderen zijn.
enum Mode { Home, Change, Add, Delete }
let mode: Mode = Mode.Home

let pageElem = dom.div()

let contentElem: HTMLElement

// Per status.
const doorfietsColors: { [status: string]: string } = {
	'1': '#ff101b', // dieprood, te nemen initiatief
	'7': '#f36c6c', // lichtrood, te nemen initiatief na 2028
	'2': '#f3ca02', // geel, studie bekend tracé
	'3': '#f8a820', // oranje, studie ontbrekende schakel
	'4': '#e699c0', // roze, in uitvoering 2025/2026
	'5': '#bdc62e', // mos, gereed maar verbeteringen mogelijk
	'6': '#5fae46', // groen, voldoet aan eisen
}

const statusText: { [status: string]: string } = {
	's1': 'Te nemen initiatief in deze bestuursperiode',
	's7': 'Te nemen initiatief in volgende bestuursperiode',
	's2': 'Studie bekend tracé',
	's3': 'Studie ontbrekende schakel',
	's4': 'In uitvoering 2025/2026',
	's5': 'Gereed maar verbeteringen mogelijk',
	's6': 'Voldoet aan eisen',
}
const statusToelichtingen: { [status: string]: HTMLElement } = {
	's1': dom.p('[toelichting bij "Te nemen initiatief in deze bestuursperiode"]'),
	's7': dom.p('[toelichting bij "Te nemen initiatief in volgende bestuursperiode"]'),
	's2': dom.p('[toelichting bij "Studie bekend tracé"]'),
	's3': dom.p('[toelichting bij "Studie ontbrekende schakel"]'),
	's4': dom.p('[toelichting bij "In uitvoering 2025/2026"]'),
	's5': dom.p('[toelichting bij "Gereed maar verbeteringen mogelijk"]'),
	's6': dom.p('[toelichting bij "Voldoet aan eisen"]'),
}

const routesColors: { [k: string]: string } = {
	r_doorfts: '#d7191c', // rood
	r_stad: '#e6e699', // geel
	r_recr: '#abdda4', // mintgroen
	r_verb: '#fdae61', // oranje
	r_regio: '#2b83ba', // blauw
}

let soortLagen: { [k: string]: boolean } = {
	r_doorfts: true,
	r_stad: true,
	r_recr: true,
	r_verb: true,
	r_regio: true,
}

try {
	const sj = window.localStorage.getItem('soortlagen')
	if (sj) {
		soortLagen = JSON.parse(sj)
	}
} catch (err) {}

const block = async <T>(fn: () => Promise<T>, disableable?: {disabled: boolean}, loadable?: {classList: DOMTokenList}): Promise<T> => {
	// todo: prevent other clicks from happening, show semi-transparent overlay
	if (disableable) {
		disableable.disabled = true
	}
	if (loadable) {
		loadable.classList.toggle('loading', true)
	}
	try {
		return await fn()
	} catch (err: any) {
		alert('Fout: '+err.message)
		throw err
	} finally {
		if (loadable) {
			loadable.classList.toggle('loading', false)
		}
		if (disableable) {
			disableable.disabled = false
		}
	}
}

// as StyleFunction /* xxx Type 'FeatureLike' is not assignable to type 'Feature<Geometry>'. */
const makeLayerStyle = (wegen: boolean, routes: boolean, doorfiets: boolean) => {
	return (f: Feature, resolution: number) => {
		let st: Style[] = []

		if (mode === Mode.Home) {
			return [
				new Style({
					stroke: new Stroke({
						color: '#444',
						width: 10-Math.round(Math.log2(resolution)),
						lineCap: 'butt',
					}),
				}),
				new Style({
					stroke: new Stroke({
						color: '#999',
						width: 8-Math.round(Math.log2(resolution)),
					}),
				}),
			]
		}

		if (!wegen && (f.get('verwijderd') || f.get('gewijzigd') || f.get('toegevoegd'))) {
			st.push(
				new Style({
					stroke: new Stroke({
						color: 'yellow',
						width: 16 + Math.round(10 - Math.log2(resolution)),
						lineCap: 'butt',
					})
				})
			)
		}

		const selected = select && !!select.getFeatures().getArray().find(sel => sel.getId() === f.getId())
		if (selected) {
			st.push(
				new Style({
					stroke: new Stroke({
						color: 'black',
						width: 20 + Math.round(10 - Math.log2(resolution)),
						lineCap: 'butt',
					}),
				})
			)
		}

		if (wegen) {
			st.push(
				new Style({
					stroke: new Stroke({
						width: 4-Math.round(Math.log2(resolution)),
						color: 'black',
					}),
				})
			)
			return st
		}

		let colors: string[] = []
		let baseWidth = 10
		if (routes) {
			// We tonen r_regio alleen als er geen andere soort ingesteld is op de lijn. In principe is alles een regionale route.
			let n = 0
			for (const [k, v] of Object.entries(routesColors)) {
				if (f.get(k) && (n === 0 || k !== 'r_regio') && soortLagen[k]) {
					colors.push(v)
					n++
				}
			}
			if (n === 0) {
				return undefined
			}
			baseWidth -= 1
		} else if (doorfiets) {
			const color = doorfietsColors[f.get('status') || ''] || ''
			if (color) {
				colors.push(color)
			}
		} else {
			alert('onbekende laag')
		}

		st.push(
			new Style({
				stroke: new Stroke({
					width: 2+Math.round(baseWidth - Math.log2(resolution)),
					color: '#000000',
					lineCap: 'butt',
				}),
			}),
		)
		colors.forEach((color, i) => {
			const dash = colors.length === 1 ? undefined : [10, (colors.length-1)*10]
			const dashOffset = colors.length === 1 ? undefined : i*10
			st.push(
				new Style({
					stroke: new Stroke({
						width: Math.round(baseWidth - Math.log2(resolution)),
						color: color,
						lineCap: 'butt',
						lineDash: dash,
						lineDashOffset: dashOffset,
					}),
				}),
			)
		})
		return st
	}
}

const wegenLayerStyle = makeLayerStyle(true, false, false) as StyleFunction
const routesLayerStyle = makeLayerStyle(false, true, false) as StyleFunction
const doorfietsLayerStyle = makeLayerStyle(false, false, true) as StyleFunction

// xxx openlayers lijkt de draad kwijt te raken qua feature class
const doorfietsSource = new VectorTileSource({
	format: new MVT({featureClass: Feature as any /*xxx*/}),
	url: '/tile/door/{z}/{x}/{y}.mvt',
}) as VectorTileSource<Feature>

const doorfietsLayer = new VectorTileLayer({
	source: doorfietsSource,
	style: doorfietsLayerStyle,
})

// xxx openlayers lijkt de draad kwijt te raken qua feature class
const routesSource = new VectorTileSource({
	format: new MVT({featureClass: Feature as any /*xxx*/}),
	url: '/tile/routes/{z}/{x}/{y}.mvt',
}) as VectorTileSource<Feature>

const routesLayer = new VectorTileLayer({
	visible: false,
	source: routesSource,
	style: routesLayerStyle,
})

const wegenSource = new VectorTileSource({
	format: new MVT({featureClass: Feature as any /*xxx*/}),
	url: '/tile/wegen/{z}/{x}/{y}.mvt',
}) as VectorTileSource<Feature>

const wegenLayer = new VectorTileLayer({
	visible: false,
	source: wegenSource,
	minZoom: 13,
	style: wegenLayerStyle,
})

// Voor selectie.
const modeLayer = {
	[Mode.Home]: routesLayer,
	[Mode.Change]: doorfietsLayer,
	[Mode.Add]: wegenLayer,
	[Mode.Delete]: routesLayer,
}

const legendaDoorfiets = (n: string) =>
	dom.span(
		dom.div(style({display: 'inline-block', width: '.75em', height: '.75em', verticalAlign: 'baseline', backgroundColor: doorfietsColors[''+n]})),
		' ' + statusText['s'+n],
	)

const setMode = (m: Mode) => {
	mode = m

	doorfietsLayer.setVisible(m === Mode.Change)
	routesLayer.setVisible(m === Mode.Add || m === Mode.Delete || m === Mode.Home)
	wegenLayer.setVisible(m === Mode.Add)

	let selectStyle: StyleFunction | undefined = undefined
	if (m === Mode.Change) {
		selectStyle = doorfietsLayerStyle
	} else if (m === Mode.Add) {
		selectStyle = wegenLayerStyle
	} else if (m === Mode.Delete) {
		selectStyle = routesLayerStyle
	} else if (m !== Mode.Home) {
		throw new Error('onbekende modus')
	}
	doorfietsSource.changed()
	routesSource.changed()
	wegenSource.changed()

	if (select) {
		map.removeInteraction(select)
		select = undefined
	}
	if (!selectStyle) {
		renderContent()
		return
	}

	select = new Select({
		multi: true,
		layers: [modeLayer[mode]],
		style: selectStyle,
	})
	select.getFeatures().on(['add', 'remove'], debounce(100, async e => {
		console.log('selection changed', e, select!.getFeatures().getArray())

		modeLayer[mode].getSource()!.changed()

		let feats = select!.getFeatures().getArray()
		console.log('selected features', feats)

		let ids: number[] = []
		for (const f of feats) {
			const id = typeof f.getId() === 'number' ? (f.getId() as number) : (parseInt((f.getId() || '') as string) as number)
			if (id && !ids.includes(id)) {
				ids.push(id)
			}
		}

		try {
			await renderSelectie(selectieElem, ids, feats)
		} catch (err: any) {
			alert('Laden van wegen: '+err.message)
		}
	}))
	map.addInteraction(select)

	renderContent()
}

const legendaRoutes = [
	{naam: 'Doorfietsroutes', color: 'rgba(215, 25, 28, 1)', key: 'r_doorfts'},
	{naam: 'Stadsroutes', color: 'rgba(230, 230, 0, 1)', key: 'r_stad'},
	{naam: 'Recreatieve routes', color: 'rgba(171, 221, 164, 1)', key: 'r_recr'},
	{naam: 'Verbindingsroutes', color: 'rgba(253, 174, 97, 1)', key: 'r_verb'},
	{naam: 'Regionale routes', color: 'rgba(43, 131, 186, 1)', key: 'r_regio'},
]

const renderContent = () => {
	if (mode === Mode.Add) {
		renderAdd()
	} else if (mode === Mode.Delete) {
		renderDelete()
	} else if (mode === Mode.Change) {
		renderChange()
	} else if (mode === Mode.Home) {
		renderHome()
	} else {
		throw new Error('unknown mode')
	}
}

const renderAdd = () => {
	dom._kids(contentElem,
		dom.div(
			style({flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}),
			dom.div(
				dom.p('Selecteer wegen op de kaart om toe te voegen, zichtbaar indien ver genoeg ingezoomd.'),
				newAddForm(),
			),
			dom.div(
				style({marginTop: '1em'}),
				dom.h1('Legenda'),
				legendaRoutes.map(l =>
					dom.label(
						style({display: 'block'}),
						dom.input(
							attr.type('checkbox'),
							soortLagen[l.key] ? attr.checked('') : [],
							function change(e: {target: HTMLInputElement}) {
								soortLagen[l.key] = e.target.checked
								window.localStorage.setItem('soortlagen', JSON.stringify(soortLagen))
								routesSource.changed()
							},
						), ' ',
						dom.div(style({display: 'inline-block', width: '.75em', height: '.75em', verticalAlign: 'baseline', backgroundColor: l.color})),
						' ' + l.naam
					)
				),
				dom.div(
					dom.input(attr.type('checkbox'), style({visibility: 'hidden'})), ' ',
					dom.div(style({display: 'inline-block', width: '.75em', height: '.75em', verticalAlign: 'baseline', backgroundColor: 'yellow', border: '1px solid #ccc'})),
					' Gewijzigd/verwijderd/toegevoegd'
				),
				dom.div(
					style({marginTop: '.5ex'}),
					'Alle: ',
					dom.clickbutton('+', style({padding: '0 .35em'}), function click() {
						for (const k in soortLagen) {
							soortLagen[k] = true
						}
						renderAdd()
						window.localStorage.setItem('soortlagen', JSON.stringify(soortLagen))
						routesSource.changed()
					}), ' ',
					dom.clickbutton('-', style({padding: '0 .35em'}), function click() {
						for (const k in soortLagen) {
							soortLagen[k] = false
						}
						renderAdd()
						window.localStorage.setItem('soortlagen', JSON.stringify(soortLagen))
						routesSource.changed()
					}),
				),
			),
		)
	)
}

const renderDelete = () => {
	dom._kids(contentElem,
		dom.div(
			style({flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}),
			dom.div(
				dom.p('Selecteer routes op de kaart om te verwijderen.'),
				newDeleteForm(),
			),
			dom.div(
				style({marginTop: '1em'}),
				dom.h1('Legenda'),
				legendaRoutes.map(l =>
					dom.label(
						style({display: 'block'}),
						dom.input(
							attr.type('checkbox'),
							soortLagen[l.key] ? attr.checked('') : [],
							function change(e: {target: HTMLInputElement}) {
								soortLagen[l.key] = e.target.checked
								window.localStorage.setItem('soortlagen', JSON.stringify(soortLagen))
								routesSource.changed()
							},
						), ' ',
						dom.div(style({display: 'inline-block', width: '.75em', height: '.75em', verticalAlign: 'baseline', backgroundColor: l.color})),
						' ' + l.naam
					)
				),
				dom.div(
					dom.input(attr.type('checkbox'), style({visibility: 'hidden'})), ' ',
					dom.div(style({display: 'inline-block', width: '.75em', height: '.75em', verticalAlign: 'baseline', backgroundColor: 'yellow', border: '1px solid #ccc'})),
					' Gewijzigd/verwijderd/toegevoegd'
				),
				dom.div(
					style({marginTop: '.5ex'}),
					'Alle: ',
					dom.clickbutton('+', style({padding: '0 .35em'}), function click() {
						for (const k in soortLagen) {
							soortLagen[k] = true
						}
						renderDelete()
						window.localStorage.setItem('soortlagen', JSON.stringify(soortLagen))
						routesSource.changed()
					}), ' ',
					dom.clickbutton('-', style({padding: '0 .35em'}), function click() {
						for (const k in soortLagen) {
							soortLagen[k] = false
						}
						renderDelete()
						window.localStorage.setItem('soortlagen', JSON.stringify(soortLagen))
						routesSource.changed()
					}),
				),
			),
		)
	)
}

const kaartInteracties = () =>
	dom.div(
		dom.p('Interacties met kaart:'),
		dom.ul(
			dom.li('Klik op een lijn om deze (als enige) te selectren.'),
			dom.li('Shift+klik op lijn om selectie aan te passen (toevoegen/verwijderen).'),
			dom.li('Ctrl+sleep om lijnstukken binnen geselecteerde extent aan selectie toe te voegen.'),
			dom.li('Ctrl+shift+sleep om lijnstukken binnen geselecteerde extent uit selectie te verwijderen.'),
			dom.li('Shift+sleep om in te zoomen op geselecteerde extent.'),
			dom.li('Scroll met muis voor in/uitzoomen.'),
		)
	)

const renderChange = () => {
	dom._kids(contentElem,
		dom.div(
			style({flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}),
			dom.div(
				dom.p('Selecteer doorfietsroutes op de kaart om statussen aan te passen.'),
				newChangeForm(),
			),
			dom.div(
				style({marginTop: '1em'}),
				dom.h1('Legenda'),
				[1, 7, 2, 3, 4, 5, 6].map(n => dom.div(legendaDoorfiets(''+n))),
				dom.div(
					dom.div(style({display: 'inline-block', width: '.75em', height: '.75em', verticalAlign: 'baseline', backgroundColor: 'yellow', border: '1px solid #ccc'})),
					' Gewijzigd/verwijderd/toegevoegd'
				),
			),
		)
	)
	renderSelectie(selectieElem, [], [])
}

const renderHome = () => {
	dom._kids(contentElem,
		dom.div(
			dom.p('Controleer de (door)fietsroutes in uw gemeente, en dien statuswijzigingen of aangepaste wegvakken in, volgens de laatste plannen van het tracé. De provincie zal de wijzigingen bekijken en daarna overnemen of eventueel navraag doen.'),
			kaartInteracties(),

			dom.div(
				dom.br(),
				dom.br(),
				dom.div('Acties:'),
				dom.br(),
				dom.div(
					dom.clickbutton('Wijzigen van de status', function click() {
						setMode(Mode.Change)
					}), ' van doorfietsroutes',
				),
				dom.br(),
				dom.div(
					dom.clickbutton('Toevoegen', function click() {
						setMode(Mode.Add)
					}), ' van wegvakken aan routes',
				),
				dom.br(),
				dom.div(
					dom.clickbutton('Verwijderen', function click() {
						setMode(Mode.Delete)
					}), ' van wegvakken van routes',
				),
			),
		),
	)
}

class BoxSelectControl extends Control {
	constructor() {
		const add = new DragBox({
			condition: conditionAlways,
		})
		add.on('boxend', () => {
			console.log('dragboxend')
			if (!select) {
				return
			}
			const extent = add.getGeometry().getExtent()
			for (const f of modeLayer[mode].getFeaturesInExtent(extent)) {
				if (!select.getFeatures().getArray().find(sf => sf.getId() === f.getId()) && f.getGeometry()!.intersectsExtent(extent)) {
					select.getFeatures().push(f)
				}
			}
		})
		const del = new DragBox({
			condition: conditionAlways,
		})
		del.on('boxend', () => {
			if (!select) {
				return
			}
			const extent = del.getGeometry().getExtent()
			for (const f of modeLayer[mode].getFeaturesInExtent(extent)) {
				if (f.getGeometry()!.intersectsExtent(extent)) {
					for (const sf of select.getFeatures().getArray().filter(sf => sf.getId() === f.getId())) {
						select.getFeatures().remove(sf)
					}
				}
			}
		})

		let interaction: DragBox | undefined
		let addBtn: HTMLButtonElement
		let delBtn: HTMLButtonElement

		const toggle = (box: DragBox, btn: HTMLButtonElement, otherBtn: HTMLButtonElement) => {
			if (interaction) {
				map.removeInteraction(interaction)
			}
			if (interaction === box) {
				btn.classList.toggle('btnactive', false)
				interaction = undefined
				return
			}
			btn.classList.toggle('btnactive', true)
			otherBtn.classList.toggle('btnactive', false)
			map.addInteraction(box)
			interaction = box
		}

		const elem = dom.div(
			style({top: '.5em', right: '.5em', fontSize: '1.3em'}),
			dom._class('ol-unselectable', 'ol-control'),
			'Selecteren ',
			addBtn=dom.clickbutton('+', style({display: 'inline-block'}), attr.title('Selectie uitbreiden door selecteren van gebied op kaart. Ook mogelijk via control + slepen op kaart.'), function click() {
				toggle(add, addBtn, delBtn)
			}), ' ',
			delBtn=dom.clickbutton('-', style({display: 'inline-block'}), attr.title('Selectie kleiner maken met wegen van in gebied op kaart. Ook mogelijk via control + shift + slepen op kaart.'), function click() {
				toggle(del, delBtn, addBtn)
			}), ' ',
			dom.clickbutton('?', style({display: 'inline-block'}), function click() {
				popup(
					dom.h1('Help'),
					kaartInteracties()
				)
			}),
		)
		super({element: elem})
	}
}

class LayerControl extends Control {
	constructor() {
		let osmBtn: HTMLButtonElement
		let lufoBtn: HTMLButtonElement
		let bgtBtn: HTMLButtonElement

		const toggle = (layer: TileLayer, btn: HTMLButtonElement) => {
			for (const b of [osmBtn, lufoBtn, bgtBtn]) {
				b.classList.toggle('btnactive', false)
			}
			btn.classList.toggle('btnactive', true)

			for (const l of [layerOSM, layerLuchtfoto, layerBGT]) {
				l.setVisible(false)
			}
			layer.setVisible(true)
		}

		const elem = dom.div(
			style({bottom: '.5em', left: '.5em'}),
			dom._class('ol-unselectable', 'ol-control'),
			osmBtn=dom.clickbutton('OSM', dom._class('btnactive'), style({display: 'inline-block', width: 'auto', padding: '0 .25em'}), attr.title('Open Streetmap'), function click() {
				toggle(layerOSM, osmBtn)
			}), ' ',
			lufoBtn=dom.clickbutton('Luchtfoto', style({display: 'inline-block', width: 'auto', padding: '0 .25em'}), attr.title('PDOK Luchtfoto'), function click() {
				toggle(layerLuchtfoto, lufoBtn)
			}), ' ',
			bgtBtn=dom.clickbutton('BGT', style({display: 'inline-block', width: 'auto', padding: '0 .25em'}), attr.title('BGT (alleen vanaf zoom-niveau 17)'), function click() {
				toggle(layerBGT, bgtBtn)
			}),
		)
		super({element: elem})
	}
}

const popupOpts = (opaque: boolean, ...kids: ElemArg[]) => {
	const origFocus = document.activeElement
	const close = () => {
		if (!root.parentNode) {
			return
		}
		root.remove()
		if (origFocus && origFocus instanceof HTMLElement && origFocus.parentNode) {
			origFocus.focus()
		}
	}
	let content: HTMLElement
	const root = dom.div(
		style({position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: opaque ? '#ffffff' : 'rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: opaque ? 3 : 1}),
		opaque ? [] : [
			function keydown(e: KeyboardEvent) {
				if (e.key === 'Escape') {
					e.stopPropagation()
					close()
				}
			},
			function click(e: MouseEvent) {
				e.stopPropagation()
				close()
			},
		],
		content=dom.div(
			attr.tabindex('0'),
			style({backgroundColor: 'white', borderRadius: '.25em', padding: '1em', boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)', border: '1px solid #ddd', maxWidth: '95vw', overflowX: 'auto', maxHeight: '95vh', overflowY: 'auto'}),
			function click(e: MouseEvent) {
				e.stopPropagation()
			},
			kids,
		)
	)
	document.body.appendChild(root)
	content.focus()
	return close
}

const popup = (...kids: ElemArg[]) => popupOpts(false, ...kids)

const debounce = <T>(ms: number, fn: (e: T) => Promise<void>) => {
	let id = 0
	return (e: T) => {
		if (id) {
			window.clearTimeout(id)
		}
		id = window.setTimeout(() => {
			fn(e)
			id = 0
		}, ms)
	}
}

let selectieElem: HTMLElement
let selectieFeats: Feature[] = []
let selectieWegen: api.Weg[] = []

const renderSelectie = async (dest: HTMLElement, ids: number[], feats: Feature[]) => {
	console.log('renderSelectie', {ids})
	if (ids.length > 0) {
		selectieWegen = await block(() => client.WegGet(ids), undefined, selectieElem) || []
	} else {
		selectieWegen = []
	}
	selectieFeats = feats

	console.log('geselecteerde wegen', selectieWegen)

	dom._kids(selectieElem,
		dom.div('Geselecteerde wegvakken:'),
		dom.ul(
			selectieWegen.length === 0 ? dom.li('(Geen)') : [],
			selectieWegen.map(w => {
				const l: (HTMLElement | string)[] = []
				const add = (e: HTMLElement | string) => {
					l.push(e)
				}
				if (w.Verwijderd) {
					add(dom.div('Verwijderd;'))
				}
				if (mode === Mode.Change) {
					add(dom.div(legendaDoorfiets(w.Status), '; '))
				}
				if (w.Straat) {
					add('Straat: '+w.Straat+'; ')
				}
				if (w.WegBeheerder) {
					add('Wegbeheerder: '+w.WegBeheerder+'; ')
				}

				if (mode === Mode.Add || mode === Mode.Delete) {
					// Alle routes zijn in principe ook regionale routes, dus we hoeven die kleur
					// (blauw) niet te tonen. Alleen als er geen andere soort actief is.
					const soortValues: [string, string, boolean][] = [
						['r_doorfts', 'Doorfietsroute', w.RouteDoorfiets],
						['r_stad', 'Stadsroute', w.RouteStad],
						['r_recr', 'Recreatieve route', w.RouteRecreatief],
						['r_verb', 'Verbindingsroute', w.RouteVerbinding],
						['r_regio', 'Regionale route', w.RouteRegio],
					]
					let n = 0
					let soortElems: HTMLElement[] = []
					for (const [key, naam, value] of soortValues) {
						if (value && (naam !== 'Regionale route' || n === 0)) {
							soortElems.push(
								dom.span(
									dom.div(style({display: 'inline-block', width: '.75em', height: '.75em', verticalAlign: 'baseline', backgroundColor: routesColors[key]})),
									' ', naam, ' '
								)
							)
							n++
						}
					}
					if (soortElems.length > 0) {
						add(dom.div(soortElems))
					}
				}

				let title = ''
				if (w.Indiener) {
					title = 'Indiener: '+w.Indiener
					if (w.Door) {
						title += ', '+w.Door
					}
					if (w.Opmerkingen) {
						title += '; Onderbouwing wijzigingsvoorstel:\n'+w.Opmerkingen
					}
					title += ';'
				}
				return dom.li(l, title ? attr.title(title) : [])
			}),
		)
	)
}

const newAddForm = () => {
	let fieldset: HTMLFieldSetElement
	let soort: HTMLSelectElement
	let indiener: HTMLInputElement
	let opmerkingen: HTMLTextAreaElement
	let statusBox: HTMLElement
	let status: HTMLSelectElement
	let statusToelichting: HTMLElement

	const statusElem = dom.div(
		dom.label(
			style({display: 'block', margin: '1ex 0'}),
			dom.div('Status'),
			status=dom.select(
				attr.required(''),
				dom.option(attr.value(''), '(Kies status)', attr.disabled(''), attr.selected('')),
				dom.option(attr.value('1'), statusText.s1),
				dom.option(attr.value('7'), statusText.s7),
				dom.option(attr.value('2'), statusText.s2),
				dom.option(attr.value('3'), statusText.s3),
				dom.option(attr.value('4'), statusText.s4),
				dom.option(attr.value('5'), statusText.s5),
				dom.option(attr.value('6'), statusText.s6),
				function change() {
					dom._kids(statusToelichting, statusToelichtingen['s'+status.value] || '')
				},
			),
		),
		statusToelichting=dom.p(),
	)

	const box = dom.div(
		dom.br(),
		dom.h1('Nieuw tracé indienen'),
		dom.div(
			dom.clickbutton('Annuleren', function click() {
				setMode(Mode.Home)
			})
		),
		dom.br(),
		selectieElem=dom.div(),
		dom.br(),
		dom.form(
			async function submit(e: SubmitEvent) {
				e.preventDefault()
				e.stopPropagation()

				const ids = selectieWegen.map(w => w.ObjectID)
				await block(() => client.WegToevoegen(ids, soort.value, soort.value === 'r_doorfts' ? status.value : '', opmerkingen.value, door, indiener.value), fieldset, box)
				window.localStorage.setItem('indiener', indiener.value)
				for (const f of selectieFeats) {
					f.set(soort.value, true)
					f.set('gewijzigd', true)
				}
				select!.getFeatures().clear()
				// We hebben dit record nog niet als feature, herlaadt vanaf vector tile.
				routesSource.clear()
				routesSource.changed()
				doorfietsSource.clear()
				doorfietsSource.changed()
				await renderSelectie(selectieElem, [], [])
			},
			fieldset=dom.fieldset(
				dom.label(
					style({display: 'block', margin: '1ex 0'}),
					dom.div('Soort'),
					soort=dom.select(
						attr.required(''),
						dom.option('(Kies soort)', attr.value(''), attr.disabled(''), attr.selected('')),
						legendaRoutes.map(lr => dom.option(attr.value(lr.key), lr.naam)),
						function change() {
							dom._kids(statusBox, soort.value === 'r_doorfts' ? statusElem : [])
						}
					),
					dom.div(style({fontStyle: 'italic'}), 'Bij het toevoegen van een soort automatisch ook de soort "Regionale route" toegevoegd.'),
				),
				statusBox=dom.div(),
				dom.label(
					style({display: 'block', margin: '1ex 0'}),
					dom.div('Naam indiener'),
					indiener=dom.input(attr.required(''), attr.value(window.localStorage.getItem('indiener') || '')),
				),
				dom.label(
					style({display: 'block', margin: '1ex 0'}),
					dom.div('Onderbouwing wijzigingsvoorstel'),
					opmerkingen=dom.textarea(attr.rows('5'), attr.required(''), style({width: '100%'})),
				),
				dom.div(
					dom.submitbutton('Nieuw tracé indienen'),
				),
			),
		),
	)
	renderSelectie(selectieElem, [], [])
	return box
}

const newDeleteForm = () => {
	let fieldset: HTMLFieldSetElement
	let soort: HTMLSelectElement
	let indiener: HTMLInputElement
	let opmerkingen: HTMLTextAreaElement

	const box = dom.div(
		dom.br(),
		dom.h1('Verwijdering tracé indienen'),
		dom.div(
			dom.clickbutton('Annuleren', function click() {
				setMode(Mode.Home)
			})
		),
		dom.br(),
		selectieElem=dom.div(),
		dom.br(),
		dom.form(
			async function submit(e: SubmitEvent) {
				e.preventDefault()
				e.stopPropagation()

				const ids = selectieWegen.map(w => w.ObjectID)
				await block(() => client.WegVerwijderen(ids, soort.value, opmerkingen.value, door, indiener.value), fieldset, box)
				window.localStorage.setItem('indiener', indiener.value)
				for (const f of selectieFeats) {
					f.set(soort.value, false)
					f.set('gewijzigd', true)
				}
				select!.getFeatures().clear()
				routesSource.changed()
				doorfietsSource.clear()
				doorfietsSource.changed()
				await renderSelectie(selectieElem, [], [])
			},
			fieldset=dom.fieldset(
				dom.label(
					style({display: 'block', margin: '1ex 0'}),
					dom.div('Soort'),
					soort=dom.select(
						attr.required(''),
						dom.option('(Kies soort)', attr.value(''), attr.disabled(''), attr.selected('')),
						legendaRoutes.map(lr => dom.option(attr.value(lr.key), lr.naam)),
					),
					dom.div(style({fontStyle: 'italic'}), 'Let op: Van dit tracé wordt alleen deze soort verwijderd. Als een ander soort route over dit tracé loopt blijft dat ongewijzigd.'),
				),
				dom.label(
					style({display: 'block', margin: '1ex 0'}),
					dom.div('Naam indiener'),
					indiener=dom.input(attr.required(''), attr.value(window.localStorage.getItem('indiener') || '')),
				),
				dom.label(
					style({display: 'block', margin: '1ex 0'}),
					dom.div('Onderbouwing wijzigingsvoorstel'),
					// Niet verplicht voor verwijderingen. Wel bij statuswijzigen en toevoegen.
					opmerkingen=dom.textarea(attr.rows('5'), style({width: '100%'})),
				),
				dom.div(
					dom.submitbutton('Verwijdering tracé indienen'),
				),
			),
		),
	)
	renderSelectie(selectieElem, [], [])
	return box
}

const newChangeForm = () => {
	let fieldset: HTMLFieldSetElement
	let status: HTMLSelectElement
	let statusToelichting: HTMLElement
	let indiener: HTMLInputElement
	let opmerkingen: HTMLTextAreaElement

	const box = dom.div(
		dom.br(),
		dom.h1('Wijziging status van tracé indienen'),
		dom.div(
			dom.clickbutton('Annuleren', function click() {
				setMode(Mode.Home)
			})
		),
		dom.br(),
		selectieElem=dom.div(),
		dom.br(),
		dom.form(
			async function submit(e: SubmitEvent) {
				e.preventDefault()
				e.stopPropagation()

				const nstatus = status.value

				const ids = selectieWegen.map(w => w.ObjectID)
				await block(() => client.WegOpslaan(ids, nstatus, opmerkingen.value, door, indiener.value), fieldset, box)
				window.localStorage.setItem('indiener', indiener.value)
				for (const f of selectieFeats) {
					f.set('status', nstatus)
					f.set('gewijzigd', true)
				}
				select!.getFeatures().clear()
				doorfietsSource.clear() // Leeg tilecache.
				doorfietsSource.changed() // Na status-wijziging.
				await renderSelectie(selectieElem, [], [])
			},
			fieldset=dom.fieldset(
				dom.label(
					style({display: 'block', margin: '1ex 0'}),
					dom.div('Nieuwe status'),
					status=dom.select(
						attr.required(''),
						dom.option(attr.value(''), '(Kies status)', attr.disabled(''), attr.selected('')),
						dom.option(attr.value('1'), statusText.s1),
						dom.option(attr.value('7'), statusText.s7),
						dom.option(attr.value('2'), statusText.s2),
						dom.option(attr.value('3'), statusText.s3),
						dom.option(attr.value('4'), statusText.s4),
						dom.option(attr.value('5'), statusText.s5),
						dom.option(attr.value('6'), statusText.s6),
						function change() {
							dom._kids(statusToelichting, statusToelichtingen['s'+status.value] || '')
						},
					),
				),
				statusToelichting=dom.p(),
				dom.label(
					style({display: 'block', margin: '1ex 0'}),
					dom.div('Naam indiener'),
					indiener=dom.input(attr.required(''), attr.value(window.localStorage.getItem('indiener') || '')),
				),
				dom.label(
					style({display: 'block', margin: '1ex 0'}),
					dom.div('Onderbouwing wijzigingsvoorstel'),
					opmerkingen=dom.textarea(attr.rows('5'), attr.required(''), style({width: '100%'})),
				),
				dom.div(
					dom.submitbutton('Status-wijziging indienen'),
				),
			),
		),
	)
	return box
}

const layerOSM = new TileLayer({
	source: new OSM(),
})

const projection = getProjection('EPSG:3857')!;
const projectionExtent = projection.getExtent();
const size = getWidth(projectionExtent) / 256;
const resolutions = []
const matrixIds = new Array(24);
for (let z = 0; z < 24; ++z) {
	resolutions[z] = size / Math.pow(2, z);
	matrixIds[z] = z;
}

const layerLuchtfoto = new TileLayer({
	visible: false,
	source: new WMTS({
		attributions: 'PDOK luchtfoto, CC BY 4.0, Kaartgegevens: &copy; <a href="https://www.pdok.nl">PDOK</a>',
		url: 'https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0?',
		layer: 'Actueel_orthoHR',
		matrixSet: 'EPSG:3857',
		format: 'image/png',
		style: 'default',
		projection: projection,
		tileGrid: new WMTSTileGrid({
			origin: getTopLeft(projectionExtent),
			resolutions: resolutions.slice(0, 22),
			matrixIds: matrixIds.slice(0, 22),
		}),
		wrapX: false
	})
})
const layerBGT = new TileLayer({
	visible: false,
	source: new WMTS({
		attributions: 'Kaartgegevens: &copy; <a href="https://www.kadaster.nl">Kadaster</a>',
		url: 'https://service.pdok.nl/lv/bgt/wmts/v1_0',
		layer: 'achtergrondvisualisatie',
		matrixSet: 'EPSG:3857',
		format: 'image/png',
		style: 'default',
		projection: projection,
		tileGrid: new WMTSTileGrid({
			origin: getTopLeft(projectionExtent),
			resolutions: resolutions,
			matrixIds: matrixIds,
		}),
		wrapX: false
	})
})

const init = async () => {
	const root = dom.div(
		pageElem,
	)
	document.getElementById('rootElem')!.replaceWith(root)

	door = decodeURIComponent(window.location.hash.substring(1).split('?')[0])

	const initView = {
		center: [551368, 6920539],
		zoom: 10,
	}
	const qstr = location.hash.split('?')[1]
	if (qstr) {
		const qs = new URLSearchParams(qstr)
		if (qs && qs.has('c')) {
			const coordstr = (qs.get('c') || '').split(',')
			const coord = [parseFloat(coordstr[0]), parseFloat(coordstr[1])]
			initView.center = transform(coord, 'EPSG:4326', 'EPSG:3857')
		}
		if (qs && qs.has('z')) {
			initView.zoom = parseFloat(qs.get('z') || '')
		}
	}
	const mapElem = dom.div(style({width: '70vw'}))

	map = new Map({
		controls: defaultControls().extend([new BoxSelectControl(), new LayerControl()]),
		target: mapElem,
		layers: [
			layerOSM,
			layerLuchtfoto,
			layerBGT,
			doorfietsLayer,
			routesLayer,
			wegenLayer,
		],
		view: new View(initView),
	})
	const viewElem = map.getViewport()

	;(window as any).map = map

	map.on('pointermove', function (e) {
		viewElem.style.cursor = !e.dragging && map.hasFeatureAtPixel(map.getEventPixel(e.originalEvent)) ? 'crosshair' : ''
	})

	let updateHashID: number = 0
	const updateHashDelayed = () => {
		if (updateHashID) {
			window.clearTimeout(updateHashID)
		}
		updateHashID = window.setTimeout(() => {
			location.hash = '#?c='+encodeURIComponent(transform(map.getView().getCenter()!, 'EPSG:3857', 'EPSG:4326').map(v => v.toFixed(4)).join(','))+'&z='+map.getView().getZoom()!.toFixed(1)
			updateHashID = 0
		}, 1000)
	}
	map.getView().on('change:center', updateHashDelayed)
	map.getView().on('change:resolution', updateHashDelayed)

	const dragBoxAdd = new DragBox({
		condition: platformModifierKeyOnly,
	})
	map.addInteraction(dragBoxAdd)
	dragBoxAdd.on('boxend', () => {
		if (!select) {
			return
		}
		const extent = dragBoxAdd.getGeometry().getExtent()
		for (const f of modeLayer[mode].getFeaturesInExtent(extent)) {
			if (!select.getFeatures().getArray().find(sf => sf.getId() === f.getId()) && f.getGeometry()!.intersectsExtent(extent)) {
				select.getFeatures().push(f)
			}
		}
	})
	const dragBoxDel = new DragBox({
		condition: (e: MapBrowserEvent<any>) => !e.originalEvent.altKey && e.originalEvent.shiftKey && platformModifierKey(e),
	})
	map.addInteraction(dragBoxDel)
	dragBoxDel.on('boxend', () => {
		if (!select) {
			return
		}
		const extent = dragBoxDel.getGeometry().getExtent()
		for (const f of modeLayer[mode].getFeaturesInExtent(extent)) {
			if (f.getGeometry()!.intersectsExtent(extent)) {
				for (const sf of select.getFeatures().getArray().filter(sf => sf.getId() === f.getId())) {
					select.getFeatures().remove(sf)
				}
			}
		}
	})

	dom._kids(pageElem,
		dom.div(
			style({display: 'flex', height: '100vh', alignContent: 'stretch', alignItems: 'stretch'}),
			// Kaart op links.
			mapElem,
			// Informatie op rechts.
			dom.div(
				style({width: '30vw', flexGrow: '1', height: '100vh', overflowY: 'scroll', display: 'flex', flexDirection: 'column'}),
				dom.div(
					style({padding: '1em 1em 0 1em', flexGrow: '1', display: 'flex', flexDirection: 'column'}),
					location.host.indexOf('-dev') > 0 || location.host.indexOf('localhost') >= 0 ? dom.div(style({backgroundColor: '#ffa544', padding: '1em', marginBottom: '1em'}), 'Let op: Ontwikkelomgeving. Wijzigingen kunnen verloren gaan.') : [],
					dom.h1('Fietsnetwerk beheer - Verzoek tot wijziging fietsroutes'),
					dom.div(
						style({flexGrow: '1', display: 'flex', flexDirection: 'column'}),
						contentElem=dom.div(
							style({flexGrow: '1', display: 'flex', flexDirection: 'column'}),
							// Content set depending on editing mode.
						)
					),
					dom.div(
						style({textAlign: 'center', marginTop: '1em'}),
						dom.img(attr.src('logos.jpg'), attr.alt("Logo's provincies Flevoland en Noord-Holland, en Vervoerregio Amsterdam"), style({maxWidth: '100%', maxHeight: '40px'})),
					),
				)
			)
		)
	)

	setMode(Mode.Home)
}

window.addEventListener('load', async () => {
	try {
		await init()
	} catch (err: any) {
		window.alert('Error: ' + err.message)
	}
})
